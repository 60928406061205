import React, {useState} from 'react'
import {
  AutocompleteArrayInput,
  Create,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin'
import {MODULE_IDS} from "../../../../config/api";

const ProcessesCreate = () => {
  const [count, setCount] = useState(0)
  return (
    <Create redirect="show" >
      <SimpleForm >
        <TextInput source="name" validate={[required()]} />
        <TextInput source="remote_name" validate={[required()]} />

        <ReferenceInput source="client.id" reference="clients" perPage={100}>
          <SelectInput optionText="name" />
        </ReferenceInput>

        <FormDataConsumer>
          {// eslint-disable-next-line no-unused-vars
            ({ formData, dispatch, ...rest }) => (
              <ReferenceArrayInput
                {...rest}
                key={`questions_${count}`}
                reference="questions"
                source="questions"
                filter={{"moduleType.id": MODULE_IDS.ID_EXIT_INTERVIEW}}
                perPage={1000}
                filterToQuery={searchText => ({ internal_name: searchText, "moduleType.id": MODULE_IDS.ID_EXIT_INTERVIEW  })}
                onChange={() => setTimeout(() => setCount(count + 1), 250)}
                allowEmpty
              >
                <AutocompleteArrayInput optionText="internal_name" />
              </ReferenceArrayInput>
            )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

export default ProcessesCreate
