import {Edit, SimpleForm, required, ReferenceArrayInput, AutocompleteArrayInput} from 'react-admin'
import { TextInput, ReferenceInput, SelectInput } from 'react-admin'
import {MODULE_IDS} from "../../../../config/api";

const ProcessEdit = () => {
  return (
    <Edit undoable={false} redirect="show" >
      <SimpleForm >
        <TextInput source="name" validate={[required()]} />
        <TextInput source="remote_name" validate={[required()]} />

        <ReferenceInput source="client.id" reference="clients" perPage={100}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput
            reference="questions"
            source="questions"
            filter={{"moduleType.id": MODULE_IDS.ID_EXIT_INTERVIEW}}
            perPage={1000}
            filterToQuery={searchText => ({ internal_name: searchText, "moduleType.id": MODULE_IDS.ID_EXIT_INTERVIEW  })}
            allowEmpty
        >
          <AutocompleteArrayInput optionText="internal_name" shouldRenderSuggestions={() => true} inputValueMatcher={() => true} />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export default ProcessEdit
