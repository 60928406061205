import React from 'react'
import { Edit, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin'
import {ColorInput} from "react-admin-color-picker";

const ReminderConfigsEdit = () => {
    return (
        <Edit redirect="show">
            <SimpleForm>
                <ReferenceInput source="client.id" reference="clients" perPage={100} validate={required()}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput source="module_type.id" reference="modules" perPage={100} label="module" validate={required()}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="name" validate={required()} />
                <TextInput source="description" validate={required()} />
                <ColorInput source="color" />
                <NumberInput source="order" validate={required()} />
            </SimpleForm>
        </Edit>
    )
}

export default ReminderConfigsEdit
