import React from 'react'
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    EditButton,
    ShowButton,
    DeleteButton,
    AutocompleteInput,
} from 'react-admin'

const DefaultMessagesFilter = [
    <ReferenceInput source="module_type.id" reference="modules" allowEmpty label="module">
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <ReferenceInput
        source="client.id"
        reference="clients"
        allowEmpty
        label="Cliente"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={1000}
    >
        <AutocompleteInput source="name" optionText="name" filterToQuery={(searchText) => ({ name: searchText })} />
    </ReferenceInput>
]

const DefaultMessagesList = () => (
    <List exporter={false} filters={DefaultMessagesFilter}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="location" />
            <TextField source="interviewer" />
            <ReferenceField source="module_type.id" reference="modules" allowEmpty label="module">
                <TextField source="name" />
            </ReferenceField>
            <EditButton />
            <ShowButton />
            <DeleteButton />
        </Datagrid>
    </List>
)

export default DefaultMessagesList
