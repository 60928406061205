import React from 'react'
import { Show, TabbedShowLayout, Tab } from 'react-admin'
import { Datagrid, ReferenceArrayField, TextField, BooleanField, ReferenceField, ChipField, NumberField } from 'react-admin'
import { Grid } from '@mui/material'
import { useRecordContext } from 'react-admin'
import { MODULE_IDS } from '../../../../config/api'

const QuestionsShow = () => (
    <Show >
        <TabbedShowLayout>
            <Tab label="summary">
                <TextField source="name" />
                <TextField source="internal_name" />
                <TextField source="remote_name" label="Variables" />
                <BooleanField source="variable_field" />
                <BooleanField source="is_editable_by_client" />
                <TextField source="order" />

                <ReferenceField source="module_type.id" reference="modules" allowEmpty label="module">
                    <Grid item xs="auto">
                        <ChipField source="name" />
                    </Grid>
                </ReferenceField>
                <TextField source="weight" />
                <ReferenceField source="profile.id" reference="profiles" linkType={false}>
                    <Grid item xs="auto">
                        <ChipField source="name" />
                    </Grid>
                </ReferenceField>
            </Tab>
            <Tab label="resources.questions.tabs.answers">
                <AnswersReferenceArrayField source="answers" />
            </Tab>

        </TabbedShowLayout>
    </Show>
)

export default QuestionsShow

const AnswersReferenceArrayField = props => {
    const record = useRecordContext();  

    return (
        record.module_type.id ===  MODULE_IDS.ID_TALENT_ACQUISITION  &&
        record.profile.id === 1 ? (
            <ReferenceArrayField {...props} reference="answers_profile">
                <Datagrid rowClick="show">
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="value" />
                    <NumberField source="score" />
                </Datagrid>
            </ReferenceArrayField>
        ) : (
            <ReferenceArrayField {...props} reference="answers_skills">
                <Datagrid rowClick="show">
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="value" />
                    <BooleanField source="punctuate" />
                    <ChipField source="skill.name" />
                </Datagrid>
            </ReferenceArrayField>
        )
    );
}
