import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EmailField,
    Filter,
    TextInput,
    DateInput,
    AutocompleteArrayInput,
    ReferenceInput, NumberField
} from "react-admin";
import ClickableIdField from "../ClickableField";
import {format} from "date-fns";

const FilterSessions = props =>{
    const today = new Date();
    const todayString = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
    const [from, setFrom] = React.useState(format(new Date(), 'yyyy-MM-dd'));
    const [to, setTo] = React.useState(formatDate(new Date()));

    function formatDate(date) {
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    }

    return (
        <Filter {...props}>
            <DateInput
                alwaysOn={true}
                source="from"
                inputProps={{
                    min: '2020-01-01',
                    max: to ? to : todayString,
                }}
                onChange={(e) => setFrom(e.target.value)}

            />
            <DateInput
                alwaysOn={true}
                source="to"
                inputProps={{
                    min: from ? from : '2020-01-01',
                    max: todayString,
                }}
                onChange={(e) => setTo(e.target.value)}
            />
            <ReferenceInput
                source="chatbots"
                reference='chatbots'
                alwaysOn={true}
            >
                <AutocompleteArrayInput
                    source={'name'}
                    optionText={'name'}
                    filterToQuery={searchText => ({ name: searchText })}
                />
            </ReferenceInput>
            <TextInput source="key" alwaysOn={true}/>
            <TextInput source="valor" alwaysOn={true}/>
        </Filter>
    );
}


const SessionsList = () => {

    return (
        <List filters={<FilterSessions/>}>
            <Datagrid>
                <ClickableIdField  source="id" style={{ color: 'blue' }}/>
                <NumberField source="chatbot"/>
                <TextField source="visited_nodes" />
                <EmailField source="variables.email" />
                <TextField source="variables.nombre" />
                <TextField source="variables.apellidos" />
                <TextField source="started_at" />
                <DateField source="first_saved_at" />
                <DateField source="last_saved_at" />
                <TextField source="finished_at" />
            </Datagrid>
        </List>
    );
}


export default SessionsList;
