import React, { useState } from 'react'
import { TextInput, NumberInput, ReferenceInput, SelectInput, ReferenceArrayInput, BooleanInput } from 'react-admin'
import { Edit, SimpleForm, required, FormDataConsumer, AutocompleteArrayInput } from 'react-admin'
import { MODULE_IDS } from '../../../../config/api'
import { Grid } from '@mui/material'

const QuestionsEdit = () => {
    const [count, setCount] = useState(0)
    return (
        <Edit redirect={"show"} undoable={false}>
            <SimpleForm >
                <Grid container spacing={2}>
                    <Grid item xs="auto">
                        <TextInput source="name" validate={[required()]} />
                    </Grid>
                    <Grid item xs="auto">
                        <TextInput source="internal_name" validate={[required()]} />
                    </Grid>
                    <Grid item xs="auto">
                        <TextInput source="remote_name" validate={[required()]} label="Variable" />
                    </Grid>
                    <Grid item xs="auto">
                        <NumberInput source="order" label="Orden" />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs="auto">
                        <BooleanInput source="variable_field" />
                    </Grid>
                    <Grid item xs="auto">
                        <BooleanInput source="is_editable_by_client" />
                    </Grid>
                </Grid>
                <FormDataConsumer>
                    {({ dispatch, ...rest }) => (
                        <ReferenceInput
                            {...rest}
                            source="module_type.id"
                            reference="modules"
                            perPage={100}
                            allowEmpty
                            label="module">
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    )}
                </FormDataConsumer>

                <FormDataConsumer>
                    {// eslint-disable-next-line no-unused-vars
                        ({ formData, ...rest }) => (
                            (formData.module_type &&
                                formData.module_type.id) === MODULE_IDS.ID_TALENT_ACQUISITION &&
                            <ReferenceInput
                                {...rest}
                                source="profile.id"
                                reference="profiles"
                                perPage={100}
                                validate={[required()]}
                            >
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                        )}
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData && formData.profile && (
                            <ReferenceArrayInput
                                {...rest}
                                key={`answers_${formData.profile.id}_${count}`}
                                source="answers"
                                reference={
                                    formData.profile.id === 1
                                        ? 'answers_profile'
                                        : 'answers_skills'
                                }
                                perPage={100}
                                allowEmpty
                                sort={{ field: 'id', order: 'DESC' }}
                                filter={{ question: null }}
                                onChange={() => setTimeout(() => setCount(count + 1), 250)}
                            >
                                <AutocompleteArrayInput
                                    optionText="name"
                                    filterToQuery={searchText => ({ name: searchText, question: null })}
                                    suggestionLimit={20}
                                />
                            </ReferenceArrayInput>
                        )}
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData }) =>
                        formData.profile &&
                        formData.module_type.id === MODULE_IDS.ID_TALENT_ACQUISITION &&
                        formData.profile.id === 1 && (
                            <NumberInput source="weight" validate={[required()]} />
                        )}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    )
}

export default QuestionsEdit
